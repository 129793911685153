import React, { useCallback, useState } from 'react'
import { AutoColumn } from '../../components/Column'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import { JSBI, TokenAmount, CAVAX, Token, WAVAX } from '@pangolindex/sdk'
import { RouteComponentProps } from 'react-router-dom'
import DoubleCurrencyLogo from '../../components/DoubleLogo'
import { useCurrency } from '../../hooks/Tokens'
import { useWalletModalToggle } from '../../state/application/hooks'
import { TYPE } from '../../theme'

import { RowBetween } from '../../components/Row'
import { CardSection, DataCard, CardNoise, CardBGImage } from '../../components/earn/styled'
import { ButtonPrimary, ButtonEmpty } from '../../components/Button'
import StakingModal from '../../components/earn/StakingModal'
import { useStakingInfo } from '../../state/stake/hooks'
import UnstakingModal from '../../components/earn/UnstakingModal'
import ClaimRewardModal from '../../components/earn/ClaimRewardModal'
import { useTokenBalance } from '../../state/wallet/hooks'
import { useActiveWeb3React } from '../../hooks'
import { useColor } from '../../hooks/useColor'
import { CountUp } from 'use-count-up'

import { wrappedCurrency } from '../../utils/wrappedCurrency'
import { currencyId } from '../../utils/currencyId'
import { useTotalSupply } from '../../data/TotalSupply'
import { usePair } from '../../data/Reserves'
import usePrevious from '../../hooks/usePrevious'
// import useUSDCPrice from '../../utils/useUSDCPrice'
import { BIG_INT_ZERO, PNG } from '../../constants'

const PageWrapper = styled(AutoColumn)`
   max-width: 640px;
   width: 100%;
 `

const PositionInfo = styled(AutoColumn) <{ dim: any }>`
   position: relative;
   max-width: 640px;
   width: 100%;
   opacity: ${({ dim }) => (dim ? 0.6 : 1)};
 `

const BottomSection = styled(AutoColumn)`
   border-radius: 12px;
   width: 100%;
   position: relative;
 `

const StyledDataCard = styled(DataCard) <{ bgColor?: any; showBackground?: any }>`
   background: radial-gradient(76.02% 75.41% at 1.84% 0%, #1e1a31 0%, #3d51a5 100%);
   z-index: 2;
   box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
   background: ${({ theme, bgColor, showBackground }) =>
		`radial-gradient(91.85% 100% at 1.84% 0%, ${bgColor} 0%,  ${showBackground ? theme.black : theme.bg5} 100%) `};
 `

const StyledBottomCard = styled(DataCard) <{ dim: any }>`
   background: ${({ theme }) => theme.bg3};
   opacity: ${({ dim }) => (dim ? 0.4 : 1)};
   margin-top: -40px;
   padding: 0 1.25rem 1rem 1.25rem;
   padding-top: 32px;
   z-index: 1;
 `

const PoolData = styled(DataCard)`
   background: none;
   border: 1px solid ${({ theme }) => theme.bg4};
   padding: 1rem;
   z-index: 1;
 `

const VoteCard = styled(DataCard)`
   background: radial-gradient(76.02% 75.41% at 1.84% 0%, #27ae60 0%, #000000 100%);
   overflow: hidden;
 `

const DataRow = styled(RowBetween)`
   justify-content: center;
   gap: 12px;

   ${({ theme }) => theme.mediaWidth.upToSmall`
     flex-direction: column;
     gap: 12px;
   `};
 `

export default function Manage({
	match: {
		params: { currencyIdA, currencyIdB }
	}
}: RouteComponentProps<{ currencyIdA: string; currencyIdB: string }>) {
	const { account, chainId } = useActiveWeb3React()

	// get currencies and pair
	const [currencyA, currencyB] = [useCurrency(currencyIdA), useCurrency(currencyIdB)]
	const tokenA = wrappedCurrency(currencyA ?? undefined, chainId)
	const tokenB = wrappedCurrency(currencyB ?? undefined, chainId)

	const [, stakingTokenPair] = usePair(tokenA, tokenB)
	const stakingInfo = useStakingInfo(stakingTokenPair)?.[0]

	const avaxPool = currencyA === CAVAX || currencyB === CAVAX

	let valueOfTotalStakedAmountInWavax: TokenAmount | undefined
	// let valueOfTotalStakedAmountInUSDC: CurrencyAmount | undefined
	let backgroundColor: string
	let token: Token | undefined
	const totalSupplyOfStakingToken = useTotalSupply(stakingInfo?.stakedAmount?.token)
	const [, avaxPngTokenPair] = usePair(CAVAX, PNG[chainId ? chainId : 43114])
	// let usdToken: Token | undefined
	if (avaxPool) {
		token = currencyA === CAVAX ? tokenB : tokenA
		const wavax = currencyA === CAVAX ? tokenA : tokenB

		// let returnOverMonth: Percent = new Percent('0')
		if (totalSupplyOfStakingToken && stakingTokenPair && wavax) {
			// take the total amount of LP tokens staked, multiply by AVAX value of all LP tokens, divide by all LP tokens
			valueOfTotalStakedAmountInWavax = new TokenAmount(
				wavax,
				JSBI.divide(
					JSBI.multiply(
						JSBI.multiply(stakingInfo.totalStakedAmount.raw, stakingTokenPair.reserveOf(wavax).raw),
						JSBI.BigInt(2) // this is b/c the value of LP shares are ~double the value of the wavax they entitle owner to
					),
					totalSupplyOfStakingToken.raw
				)
			)
		}

		// get the USD value of staked wavax
		// usdToken = wavax


	} else {
		var png
		if (tokenA && tokenA.equals(PNG[tokenA.chainId])) {
			token = tokenB
			png = tokenA
		} else {
			token = tokenA
			png = tokenB
		}

		if (totalSupplyOfStakingToken && stakingTokenPair && avaxPngTokenPair && tokenB && png) {
			const oneToken = JSBI.BigInt(1000000000000000000)
			const avaxPngRatio = JSBI.divide(JSBI.multiply(oneToken, avaxPngTokenPair.reserveOf(WAVAX[tokenB.chainId]).raw),
				avaxPngTokenPair.reserveOf(png).raw)

			const valueOfPngInAvax = JSBI.divide(JSBI.multiply(stakingTokenPair.reserveOf(png).raw, avaxPngRatio), oneToken)

			valueOfTotalStakedAmountInWavax = new TokenAmount(WAVAX[tokenB.chainId],
				JSBI.divide(
					JSBI.multiply(
						JSBI.multiply(stakingInfo.totalStakedAmount.raw, valueOfPngInAvax),
						JSBI.BigInt(2) // this is b/c the value of LP shares are ~double the value of the wavax they entitle owner to
					),
					totalSupplyOfStakingToken.raw
				)
			)
		}
		// usdToken = png
	}

	// get the color of the token
	backgroundColor = useColor(token)

	// const USDPrice = useUSDCPrice(usdToken)
	// valueOfTotalStakedAmountInUSDC =
	// 		valueOfTotalStakedAmountInWavax && USDPrice?.quote(valueOfTotalStakedAmountInWavax)

	// detect existing unstaked LP position to show add button if none found
	const userLiquidityUnstaked = useTokenBalance(account ?? undefined, stakingInfo?.stakedAmount?.token)
	const showAddLiquidityButton = Boolean(stakingInfo?.stakedAmount?.equalTo('0') && userLiquidityUnstaked?.equalTo('0'))

	// toggle for staking modal and unstaking modal
	const [showStakingModal, setShowStakingModal] = useState(false)
	const [showUnstakingModal, setShowUnstakingModal] = useState(false)
	const [showClaimRewardModal, setShowClaimRewardModal] = useState(false)

	// fade cards if nothing staked or nothing earned yet
	const disableTop = !stakingInfo?.stakedAmount || stakingInfo.stakedAmount.equalTo(JSBI.BigInt(0))


	// get WAVAX value of staked LP tokens

	// let valueOfTotalStakedAmountInWAVAX: TokenAmount | undefined
	// if (totalSupplyOfStakingToken && stakingTokenPair && stakingInfo && WAVAX) {
	// 	// take the total amount of LP tokens staked, multiply by AVAX value of all LP tokens, divide by all LP tokens
	// 	valueOfTotalStakedAmountInWAVAX = new TokenAmount(
	// 		WAVAX,
	// 		JSBI.divide(
	// 			JSBI.multiply(
	// 				JSBI.multiply(stakingInfo.totalStakedAmount.raw, stakingTokenPair.reserveOf(WAVAX).raw),
	// 				JSBI.BigInt(2) // this is b/c the value of LP shares are ~double the value of the WAVAX they entitle owner to
	// 			),
	// 			totalSupplyOfStakingToken.raw
	// 		)
	// 	)
	// }

	const countUpAmount = stakingInfo?.earnedAmount?.toFixed(6) ?? '0'
	const countUpAmountPrevious = usePrevious(countUpAmount) ?? '0'


	const toggleWalletModal = useWalletModalToggle()

	const handleDepositClick = useCallback(() => {
		if (account) {
			setShowStakingModal(true)
		} else {
			toggleWalletModal()
		}
	}, [account, toggleWalletModal])

	return (
		<PageWrapper gap="lg" justify="center">
			<RowBetween style={{ gap: '24px' }}>
				<TYPE.mediumHeader style={{ margin: 0 }}>
					{currencyA?.symbol}-{currencyB?.symbol} Liquidity Mining
         </TYPE.mediumHeader>
				<DoubleCurrencyLogo currency0={currencyA ?? undefined} currency1={currencyB ?? undefined} size={24} />
			</RowBetween>

			<DataRow style={{ gap: '24px' }}>
				<PoolData>
					<AutoColumn gap="sm">
						<TYPE.body style={{ margin: 0 }}>Total deposits</TYPE.body>
						<TYPE.body fontSize={24} fontWeight={500}>
							{`${valueOfTotalStakedAmountInWavax?.toSignificant(4, { groupSeparator: ',' }) ?? '-'} AVAX`}
							{/* {valueOfTotalStakedAmountInUSDC
							? `$${valueOfTotalStakedAmountInUSDC.toFixed(0, { groupSeparator: ',' })}`
							: `${valueOfTotalStakedAmountInWavax?.toSignificant(4, { groupSeparator: ',' }) ?? '-'} AVAX`} */}
						</TYPE.body>
					</AutoColumn>
				</PoolData>
				<PoolData>
					<AutoColumn gap="sm">
						<TYPE.body style={{ margin: 0 }}>Pool Rate</TYPE.body>
						<TYPE.body fontSize={24} fontWeight={500}>
							{stakingInfo?.totalRewardRate
								?.multiply((60 * 60 * 24 * 7).toString())
								?.toFixed(0, { groupSeparator: ',' }) ?? '-'}
							{' PNG / week'}
						</TYPE.body>
					</AutoColumn>
				</PoolData>
			</DataRow>

			{showAddLiquidityButton && (
				<VoteCard>
					<CardBGImage />
					<CardNoise />
					<CardSection>
						<AutoColumn gap="md">
							<RowBetween>
								<TYPE.white fontWeight={600}>Step 1. Get Pangolin Liquidity tokens (PGL)</TYPE.white>
							</RowBetween>
							<RowBetween style={{ marginBottom: '1rem' }}>
								<TYPE.white fontSize={14}>
									{`PGL tokens are required. Once you've added liquidity to the ${currencyA?.symbol}-${currencyB?.symbol} pool you can stake your liquidity tokens on this page.`}
								</TYPE.white>
							</RowBetween>
							<ButtonPrimary
								padding="8px"
								borderRadius="8px"
								width={'fit-content'}
								as={Link}
								to={`/add/${currencyA && currencyId(currencyA)}/${currencyB && currencyId(currencyB)}`}
							>
								{`Add ${currencyA?.symbol}-${currencyB?.symbol} liquidity`}
							</ButtonPrimary>
						</AutoColumn>
					</CardSection>
					<CardBGImage />
					<CardNoise />
				</VoteCard>
			)}

			{stakingInfo && (
				<>
					<StakingModal
						isOpen={showStakingModal}
						onDismiss={() => setShowStakingModal(false)}
						stakingInfo={stakingInfo}
						userLiquidityUnstaked={userLiquidityUnstaked}
					/>
					<UnstakingModal
						isOpen={showUnstakingModal}
						onDismiss={() => setShowUnstakingModal(false)}
						stakingInfo={stakingInfo}
					/>
					<ClaimRewardModal
						isOpen={showClaimRewardModal}
						onDismiss={() => setShowClaimRewardModal(false)}
						stakingInfo={stakingInfo}
					/>
				</>
			)}

			<PositionInfo gap="lg" justify="center" dim={showAddLiquidityButton}>
				<BottomSection gap="lg" justify="center">
					<StyledDataCard disabled={disableTop} bgColor={backgroundColor} showBackground={!showAddLiquidityButton}>
						<CardSection>
							<CardBGImage desaturate />
							<CardNoise />
							<AutoColumn gap="md">
								<RowBetween>
									<TYPE.white fontWeight={600}>Your liquidity deposits</TYPE.white>
								</RowBetween>
								<RowBetween style={{ alignItems: 'baseline' }}>
									<TYPE.white fontSize={36} fontWeight={600}>
										{stakingInfo?.stakedAmount?.toSignificant(6) ?? '-'}
									</TYPE.white>
									<TYPE.white>
										PGL {currencyA?.symbol}-{currencyB?.symbol}
									</TYPE.white>
								</RowBetween>
							</AutoColumn>
						</CardSection>
					</StyledDataCard>
					<StyledBottomCard dim={stakingInfo?.stakedAmount?.equalTo(JSBI.BigInt(0))}>
						<CardBGImage desaturate />
						<CardNoise />
						<AutoColumn gap="sm">
							<RowBetween>
								<div>
									<TYPE.black>Your unclaimed PNG</TYPE.black>
								</div>
								{stakingInfo?.earnedAmount && JSBI.notEqual(BIG_INT_ZERO, stakingInfo?.earnedAmount?.raw) && (
									<ButtonEmpty
										padding="8px"
										borderRadius="8px"
										width="fit-content"
										onClick={() => setShowClaimRewardModal(true)}
									>
										Claim
									</ButtonEmpty>
								)}
							</RowBetween>
							<RowBetween style={{ alignItems: 'baseline' }}>
								<TYPE.largeHeader fontSize={36} fontWeight={600}>
									<CountUp
										key={countUpAmount}
										isCounting
										decimalPlaces={4}
										start={parseFloat(countUpAmountPrevious)}
										end={parseFloat(countUpAmount)}
										thousandsSeparator={','}
										duration={1}
									/>
								</TYPE.largeHeader>
								<TYPE.black fontSize={16} fontWeight={500}>
									<span role="img" aria-label="wizard-icon" style={{ marginRight: '8px ' }}>
										⚡
                   </span>
									{stakingInfo?.rewardRate
										?.multiply((60 * 60 * 24 * 7).toString())
										?.toSignificant(4, { groupSeparator: ',' }) ?? '-'}
									{' PNG / week'}
								</TYPE.black>
							</RowBetween>
						</AutoColumn>
					</StyledBottomCard>
				</BottomSection>
				<TYPE.main style={{ textAlign: 'center' }} fontSize={14}>
					<span role="img" aria-label="wizard-icon" style={{ marginRight: '8px' }}>
						⭐️
           </span>
           When you withdraw, the contract will automagically claim PNG on your behalf!
         </TYPE.main>

				{!showAddLiquidityButton && (
					<DataRow style={{ marginBottom: '1rem' }}>
						<ButtonPrimary padding="8px" borderRadius="8px" width="160px" onClick={handleDepositClick}>
							{stakingInfo?.stakedAmount?.greaterThan(JSBI.BigInt(0)) ? 'Deposit' : 'Deposit PGL Tokens'}
						</ButtonPrimary>

						{stakingInfo?.stakedAmount?.greaterThan(JSBI.BigInt(0)) && (
							<>
								<ButtonPrimary
									padding="8px"
									borderRadius="8px"
									width="160px"
									onClick={() => setShowUnstakingModal(true)}
								>
									Withdraw
                 </ButtonPrimary>
							</>
						)}
					</DataRow>
				)}
				{!userLiquidityUnstaked ? null : userLiquidityUnstaked.equalTo('0') ? null : (
					<TYPE.main>{userLiquidityUnstaked.toSignificant(6)} PGL tokens available</TYPE.main>
				)}
			</PositionInfo>
		</PageWrapper>
	)
}